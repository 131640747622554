import { React, useState, useEffect } from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, Grid, Paper, Typography } from "@material-ui/core";

function FromTo() {
  const [start, setStart] = useState(dayjs());
  const [end, setEnd] = useState(dayjs());
  const [diff, setDiff] = useState(0);

  const calDate = (s, e) => {
    return e.diff(s, "d");
  };

  useEffect(() => {
    setDiff(calDate(start, end));
  }, [start, end]);

  return (
    <Paper elevation={10}>
      <Grid container>
        <Box>
          <h3>start date</h3>
          <DatePicker onChange={setStart} value={start}/>
        </Box>
        <Box>
          <h3>end date</h3>
          <DatePicker onChange={setEnd} value={end}/>
        </Box>
      </Grid>
      <Typography variant="h3">diff: {diff}</Typography>
    </Paper>
  );
}

export default FromTo;
