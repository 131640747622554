import { React, useState, useEffect } from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, Grid, Paper, Typography } from "@material-ui/core";

function DDay() {
  const start = dayjs();
  const [end, setEnd] = useState(dayjs());
  const [diff, setDiff] = useState(0);

  const calDate = (s, e) => {
    return e.diff(s, "d");
  };

  useEffect(() => {
    setDiff(calDate(start, end));
  }, [start, end]);

  return (
    <Paper elevation={10}>
      <Grid container>
        <Box>
          <h3>Today</h3>
          <DatePicker readOnly value={start} />
        </Box>
        <Box>
          <h3>Target Date</h3>
          <DatePicker onChange={setEnd} value={end}/>
        </Box>
      </Grid>
      <Typography variant="h3">D-{diff}</Typography>
    </Paper>
  );
}

export default DDay;
