import DDay from "./DDay";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FromTo from "./FromTo";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { CalendarMonth } from "@mui/icons-material";
import { Button, Container } from "@mui/material";

const pages = ["D-Day", "Diff"];

function App() {
  return (
    <Box>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <CalendarMonth />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              DateCal
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  // onClick={}
                  sx={{ my: 2, color: "white", display: "inline" }}
                >
                  {page}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <DDay />
          <FromTo />
        </Grid>
      </LocalizationProvider>
    </Box>
  );
}

export default App;
